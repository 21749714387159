import React, { useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PropTypes from "prop-types";

import { useLazyQuery, useMutation, gql } from '@apollo/client';

import background from "./../images/home-consoles-preorder.jpg"
import logo from "./../images/logo-black-new.png"
import Form from "./../components/form"

const IndexPage = ({ pageContext }) => {
  const { locale, localeResources: { translation } } = pageContext
  const [checkKey, { loading, data }] = useLazyQuery(CHECK_KEY);
  const [createUser] = useMutation(ADD_USER);
  const [input, setInput] = useState('');
  const [keySubmitted, setKeySubmitted] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await checkKey({ variables: { serial_key: input } });
      setKeySubmitted(true)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmitted = async (form) => {
    try {
      await createUser({
        variables: {
          serial_key: input,
          email: form.email,
          optin_focus: form.optinFocus,
          optin_iss: form.optinISS,
          platform: form.platform,
          country: form.country
        }
      });
      setFormSubmitted(true)
    } catch (error) {
      console.log(error)
    }
  }

  const formatKey = (key) => {
    if (!key) return;
    let hyphen = key.toUpperCase().split('-').join('').split(' ').join(''); // Remove dash (-) and space if mistakenly entered.
    return hyphen.match(/.{1,4}/g).join('-');
  }

  return (
    <Layout>
      <SEO
        title="Home"
        lang={locale}
      />
      {!formSubmitted && !data?.serial?.serial_key && (
        <section className="flex items-center justify-center min-h-screen bg-fixed bg-center" style={{ backgroundImage: `url('${background}')` }}>
          <div className="px-6 md:px-0">
            <img style={{ maxWidth: "580px" }} className="w-full" src={logo} />
            <form>
              <h1 className="mt-12 text-3xl text-center uppercase sm:text-5xl font-display">{translation.redeemCode} :</h1>
              <div className="relative mt-2 shadow-sm">
                <input
                  maxLength="19"
                  onChange={e => {
                    let value;
                    if (e.target.value) {
                      value = formatKey(e.target.value)
                    } else {
                      value = e.target.value;
                    }
                    setInput(value)
                  }}
                  className="block w-full px-4 py-3 border-0 rounded-none text-md form-input"
                  value={input}
                  placeholder={translation.enterRedeemCode} />
              </div>
              {loading && (<p className="mt-1 text-sm">Loading...</p>)}
              {!loading && keySubmitted && !data?.serial?.serial_key && (<p className="mt-1 text-sm text-red-600">Wrong key</p>)}
              <div className="mt-4 text-right">
                <button onClick={handleClick} className="px-3 py-2 text-lg font-bold text-white uppercase bg-red-700 hover:bg-red-600">{translation.step2Form8}</button>
              </div>
            </form>

          </div>
        </section>
      )}
      {!formSubmitted && data?.serial?.serial_key && (
        <Form formSubmitted={handleSubmitted} translation={translation} />
      )}
      {formSubmitted && (
        <section className="flex flex-col items-center justify-center h-screen bg-center bg-cover" style={{ backgroundImage: `url('${background}')` }}>
          <img className="mx-auto" style={{ maxWidth: "580px" }} src={logo} />
          <div className="max-w-xl">
            <div className="relative mt-8">
              <div className="blur"></div>
              <div className="relative px-2 py-2 font-semibold text-md">
                <p>{translation.step31}</p>
                <p>{translation.step32}</p>
              </div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
}

const CHECK_KEY = gql`
  query checkKey($serial_key: String!) {
    serial(serial_key: $serial_key) {
      serial_key
    }
  }
`

const ADD_USER = gql`
  mutation createUserMutation($serial_key: String!, $email: String!, $optin_focus: Boolean!, $optin_iss: Boolean!, $country: String!, $platform: String!) {
    createUser(serial_key: $serial_key, email: $email, optin_focus: $optin_focus, optin_iss: $optin_iss, country: $country, platform: $platform) {
      email
    }
  }
`;


IndexPage.propTypes = {
  pageContext: PropTypes.object,
};

export default IndexPage;
