import React, { useState } from "react";
import PropTypes from "prop-types";

const Autocomplete = (props) => {
    const [searchInput, setSearchInput] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [inputRequiredError, setInputRequiredError] = useState(false);

    const onChange = (e) => {
        const search = e.target.value;
        const filteredSuggestions = props.suggestions.filter(
            (suggestion) =>
                suggestion.toLowerCase().indexOf(search.toLowerCase()) > -1
        );

        props.onSelected({ selected: search, isValid: !inputRequiredError && filteredSuggestions.length === 1 && search.toLowerCase() === filteredSuggestions[0].toLowerCase() })
        setSearchInput(search);
        setFilteredSuggestions(filteredSuggestions)
        setShowSuggestions(search.length)
        setActiveSuggestion(0);
    };

    const onKeyDown = e => {

        // Enter
        if (e.keyCode === 13) {
            e.preventDefault();
            setActiveSuggestion(0);
            setShowSuggestions(false)
            props.onSelected({ selected: filteredSuggestions[activeSuggestion], isValid: !inputRequiredError })
            setSearchInput(filteredSuggestions[activeSuggestion])
        }
        // Up
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            setActiveSuggestion(activeSuggestion - 1)
        }
        // Down
        else if (e.keyCode === 40) {
            if (activeSuggestion + 2 > filteredSuggestions.length) {
                return;
            }
            setActiveSuggestion(activeSuggestion + 1)
        }
    };


    const handleClick = (e) => {
        setActiveSuggestion(0);
        setFilteredSuggestions([]);
        setShowSuggestions(false);
        setSearchInput(e.currentTarget.innerText)
        props.onSelected({ selected: e.currentTarget.innerText, isValid: true });
    };

    return (
        <div className="relative">
            <div className="shadow-sm">
                <input type="search"
                    required
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    value={searchInput}
                    placeholder={props.placeholder}
                    onBlur={() => {
                        if (!searchInput.length) {
                            setInputRequiredError(true);
                        } else {
                            setInputRequiredError(false);
                        }
                    }}
                    className={`${(showSuggestions && filteredSuggestions.length < 1) || inputRequiredError ? 'border-red-600' : ''} block w-full transition duration-150 ease-in-out rounded-none form-input sm:text-sm sm:leading-5`} />
            </div>
            <div style={{ maxHeight: "300px" }} className={`${showSuggestions && filteredSuggestions.length > 0 ? 'transform opacity-100 scale-100 ease-in' : 'transform opacity-0 scale-95 pointer-events-none ease-out'} overflow-y-auto absolute left-0 z-50 w-full mt-1 origin-top-left shadow-lg transition  duration-100`}>
                <div className="h-full bg-white shadow-xs">
                    <div className="py-1">
                        {showSuggestions && filteredSuggestions.map((suggestion, index) => (
                            <div className={`${activeSuggestion === index ? 'bg-blue-100 hover:bg-blue-100' : ''} block px-4 py-2 text-sm leading-5 text-gray-700 cursor-pointer hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900`}
                                key={index}
                                onClick={handleClick}>
                                {suggestion}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {inputRequiredError && (<p className="mt-1 text-sm text-red-600">Field required.</p>)}
            {showSuggestions && !filteredSuggestions.length ? (<p className="mt-1 text-sm text-red-600">Country not found</p>) : (<></>)}
        </div>
    )
}

Autocomplete.propTypes = {
    suggestions: PropTypes.array,
    placeholder: PropTypes.string,
    onSelected: PropTypes.func
};

export default Autocomplete;