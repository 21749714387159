import React, { useState } from "react";
import PropTypes from "prop-types";

import Autocomplete from "./autocomplete"
import countryList from "./../constants/countries"
import background from "./../images/home-consoles-preorder.jpg"
import logo from "./../images/logo-black-new.png"

const Form = (props) => {
    const [form, setForm] = useState({
        country: "",
        platform: "ps4",
        email: "",
        email_confirmation: "",
        optinISS: false,
        optinFocus: false,
        legals: false
    });
    const [isCountryValid, setIsCountryValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [emailRequiredError, setEmailRequiredError] = useState(false);

    const updateField = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const submitForm = (event) => {
        event.preventDefault();
        props.formSubmitted(form);
    }

    const handleSelected = (data) => {
        setForm({
            ...form,
            country: data.selected.toLowerCase()
        })
        setIsCountryValid(data.isValid);
    }

    const validateEmail = (email) => {
        let re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const compare = (email, confirmation) => {
        return email === confirmation;
    }

    return (
        <section className="flex flex-col items-center justify-center h-full py-6 bg-fixed bg-center" style={{ backgroundImage: `url('${background}')` }}>
            <img className="w-full mx-auto" style={{ maxWidth: "580px" }} src={logo} />
            <form className="max-w-xl" onSubmit={submitForm}>
                <div className="relative mt-8">
                    <div className="blur"></div>
                    <div className="relative px-2 pt-2 font-semibold text-md">
                        <p>{props.translation.step21}</p>
                        <p>{props.translation.step22}</p>
                        <p>{props.translation.step23}</p>
                    </div>
                    <div className="relative px-2 mt-6">

                        <Autocomplete onSelected={handleSelected} suggestions={countryList} placeholder={props.translation.step2Form1} />
                    </div>
                    <div className="relative px-2 mt-2">
                        <div className="shadow-sm">
                            <select name="platform" value={form.platform} onChange={updateField} className="block w-full transition duration-150 ease-in-out rounded-none cursor-pointer form-select sm:text-sm sm:leading-5">
                                <option value="ps4">PlayStation®4</option>
                                <option value="xone">Xbox One</option>
                            </select>
                        </div>
                    </div>
                    <div className="relative px-2 mt-2">
                        <div className="shadow-sm">
                            <input
                                type="email"
                                name="email"
                                required
                                value={form.email}
                                onChange={updateField}
                                onBlur={() => {
                                    if (form.email && validateEmail(form.email)) {
                                        setIsEmailValid(true)
                                    } else {
                                        setIsEmailValid(false)
                                    }
                                    if (!form.email) {
                                        setEmailRequiredError(true)
                                    } else {
                                        setEmailRequiredError(false)
                                    }
                                }}
                                placeholder={props.translation.step2Form3}
                                className={`${!compare(form.email, form.email_confirmation) || !isEmailValid ? 'border-red-600' : ''} block w-full transition duration-150 ease-in-out rounded-none form-input sm:text-sm sm:leading-5`} />
                        </div>
                        {emailRequiredError && (<p className="mt-1 text-sm text-red-600">This field is required.</p>)}
                        {!isEmailValid && (<p className="mt-1 text-sm text-red-600">This field must be a valid email.</p>)}
                    </div>
                    <div className="relative px-2 mt-2">
                        <div className="shadow-sm">
                            <input type="email" name="email_confirmation" required value={form.email_confirmation} onBlur={() => {
                                compare(form.email, form.email_confirmation)
                            }} onChange={updateField} placeholder={props.translation.step2Form4} className={`${!compare(form.email, form.email_confirmation) ? 'border-red-600' : ''} block w-full transition duration-150 ease-in-out rounded-none form-input sm:text-sm sm:leading-5`} />
                        </div>
                        {!compare(form.email, form.email_confirmation) && (<p className="mt-1 text-sm text-red-600">Email confirmation is different than your email.</p>)}
                    </div>
                    <div className="relative flex items-start px-2 mt-6">
                        <div className="absolute flex items-center h-5">
                            <input required id="read" name="legals" value={form.legals} onClick={() => {
                                setForm({
                                    ...form,
                                    legals: !form.legals
                                })
                            }} type="checkbox" className="w-4 h-4 text-red-700 transition duration-150 ease-in-out form-checkbox" />
                        </div>
                        <div className="pl-8 text-sm leading-6">
                            <label htmlFor="read" className="font-medium text-gray-700 select-none" dangerouslySetInnerHTML={{ __html: props.translation.step2Form5 }}></label>
                        </div>
                    </div>
                    <div className="relative flex items-start px-2 mt-4">
                        <div className="absolute flex items-center h-5">
                            <input id="inform" name="optinISS" value={form.optin} onClick={() => {
                                setForm({
                                    ...form,
                                    optinISS: !form.optinISS
                                })
                            }} type="checkbox" className="w-4 h-4 text-red-700 transition duration-150 ease-in-out form-checkbox" />
                        </div>
                        <div className="pl-8 text-sm leading-6">
                            <label htmlFor="inform" className="font-medium text-gray-700 select-none" dangerouslySetInnerHTML={{ __html: props.translation.step2Form6 }}></label>
                        </div>
                    </div>
                    <div className="relative flex items-start px-2 pb-2 mt-4">
                        <div className="absolute flex items-center h-5">
                            <input id="inform-focus" name="optinFocus" value={form.optinFocus} onClick={() => {
                                setForm({
                                    ...form,
                                    optinFocus: !form.optinFocus
                                })
                            }} type="checkbox" className="w-4 h-4 text-red-700 transition duration-150 ease-in-out form-checkbox" />
                        </div>
                        <div className="pl-8 text-sm leading-6">
                            <label htmlFor="inform-focus" className="font-medium text-gray-700 select-none" dangerouslySetInnerHTML={{ __html: props.translation.step2Form7 }}></label>
                        </div>
                    </div>
                    <div className="relative px-2 mt-4 text-right">
                        <button disabled={!compare(form.email, form.email_confirmation) || !isCountryValid || !validateEmail(form.email) || !form.legals} type="submit" className={`${!compare(form.email, form.email_confirmation) || !isCountryValid || !validateEmail(form.email) || !form.legals ? 'bg-gray-500 cursor-not-allowed' : 'bg-red-700 hover:bg-red-600'} px-3 py-2 text-lg font-bold text-white uppercase transition ease-in-out duration-150`}>{props.translation.step2Form8}</button>
                    </div>
                    <div className="relative px-2 pb-4 mt-12 text-xs">
                        <p dangerouslySetInnerHTML={{ __html: props.translation.step2legals1 }}></p>
                        <p dangerouslySetInnerHTML={{ __html: props.translation.step2legals2 }}></p>
                    </div>
                </div>
            </form>
        </section>
    )
}

Form.propTypes = {
    formSubmitted: PropTypes.func,
    translation: PropTypes.object
};

export default Form;